<template>
  <div class="home-help-you">
    <UiHeading
      :h="'2'"
      :type="'B4'"
      :text="'すでにホームページを持っている、<br class=sp-tb-only>他のホームページ業者と<br class=sp-only>契約している場合'"
      :class="{ 'c-button _shiny': scrollY > 11400 }"
      class="mb"
    />
    <div class="wrap cover1">
      <div class="left">
        <p>
          すでにホームページを持っている、他のホームページ業者と契約している場合も、リニューアルによる制作が可能です。業者の切り替えもスムーズに行います。現在の業者へお伝えする内容などを弊社がすべて指示いたします。
        </p>
        <p>
          現在のホームページ業作に対して、ただホームページを持っているだけで月々6,600円(税込)以上の保守費用や管理費を支払っている場合は、弊社に業者を切り替えることで、月々のコストダウンを実現できることがあります。ホームページのリニューアルをせず、管理を弊社に切り替えるだけの対応も可能です。ご希望の場合は、現在のホームページをお見せいただきたいです。
        </p>
      </div>
      <img
        alt="ホームページイメージ"
        src="@/assets/GH-syoukai-ira-006.png"
        class="pc-img"
      />
    </div>

    <UiHeading
      :h="'2'"
      :type="'B4'"
      :text="'少しでも気になりましたら、<br class=sp-only>お気軽にお問い合わせください'"
       :class="{ 'c-button _shiny': scrollY > 12800 }"
       class="mb"
    />

    <div class="wrap cover2">
      <div class="left2">
        <p>ご状況によって、以下2つのお問い合わせ方法をご用意しています。</p>
        <h3>■初回無料相談</h3>
        <p>
          ホームページに対するご希望やお悩みなどをご相談ください。一般的な業者の初回打ち合わせは、「ただ希望内容をヒアリングするだけ」の場合が多いですが、弊社は全国的な実績のあるマーケティング担当者が「こういうホームページを作ると目的が達成できます」ということを具体的にアドバイスしています。そのため、初回相談によってご自身の希望がどのように形になるのかイメージを持っていただくことができます。また、現在の事業をより良くするための改善策などもお伝えすることができます。
        </p>
        <h3>■簡易提案とお見積り</h3>
        <p>
          制作したいホームページのご希望、お悩み、実装したい機能などをお伝えいただければ、簡易的なご提案とお見積りをいたします。こちらを確認いただいた後、初回相談へのお申し込みをご検討いただく流れとなっています。
        </p>
      </div>
      <div class="container ratio-200 pc-only">
        <img
          alt="お問い合わせ方法イメージ1"
          src="@/assets/GH-syoukai-ira-007-1.png"
          class="image"
        />
        <img
          alt="お問い合わせ方法イメージ2"
          src="@/assets/GH-syoukai-ira-007-2.png"
          class="image"
        />
      </div>
      <div class="container ratio-200 sp-only">
        <img
          alt="お問い合わせ方法イメージ1"
          src="@/assets/GH-syoukai-ira-007sp-1.png"
          class="image"
        />
        <img
          alt="お問い合わせ方法イメージ2"
          src="@/assets/GH-syoukai-ira-007sp-2.png"
          class="image"
        />
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import UiHeading from "@/components/Ui/Heading.vue";
// import HomeReasonReason1 from "@/components/Home/Reason/Reason1.vue";

export default {
  name: "HomeHelpYou",
  components: {
    UiHeading,
  },
  props: {},
  data() {
    return {
            scrollY: 0,
      scrollTimer: null,
    };
  },
  computed: {
    ...mapGetters(["rootUrl"]),
  },
  watch: {},
    beforeDestroy() {
    window.removeEventListener("scroll", this.handleScroll);
  },
  created() {
    window.addEventListener("scroll", this.handleScroll);
  },
    methods: {
    handleScroll() {
      if (this.scrollTimer === null) {
        this.scrollTimer = setTimeout(
          function () {
            this.scrollY = window.scrollY;
            clearTimeout(this.scrollTimer);
            this.scrollTimer = null;
            // console.log(this.scrollY);
          }.bind(this),
          200
        );
      }
    },
  },
  mounted() {
  // window.addEventListener("scroll", this.getScroll)
},
};
</script>

<style lang="scss" scoped>
.home-help-you {
  .cover1,.cover2 {
    margin: 30px auto 0px;
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    width: 100%;
        @media screen and (min-width:768px) and ( max-width:1024px){
      align-items: flex-end;
    }
    @media screen and (max-width: 767px) {
      flex-direction: column;
      align-items: flex-start;
      margin-bottom: 0px;
    }
    p {
      padding-bottom: 20px !important;
    }

    .left {
      width: 75%;
      @media screen and (max-width: 767px) {
        width: 100%;
      }
    }
    .left2 {
      width: 70%;
      @media screen and (max-width: 767px) {
        width: 100%;
      }
      h3 {
        color: var(--sub);
      }
    }
    .pc-img,
    .container {
      margin-top: 20px;
    }
    .pc-img {
      width: 20%;
      @media screen and (max-width: 767px) {
        width: 70%;
        margin-left: auto;
        margin-right: auto;
      }
    }
    .container {
      width: 18%;
      @media screen and (max-width: 767px) {
        width: 100%;
        height: 300px;
        margin-right: 0;
        // height: 130px;
      }
    }
  }
  .cover1{
    @media screen and (max-width: 767px) {
      margin-bottom: 50px;
    }
  }

}
.mb{
  margin-top: 30px;
}
.c-button._shiny {
  // margin-top: 30px;
  // margin-bottom: 10px;
  display: block;
  position: relative;
  // max-width: 250px;
  color: #fff;
  text-align: center;
  text-decoration: none;
  overflow: hidden;
  transition: 300ms;
}
.c-button._shiny::before {
  position: absolute;
  content: "";
  display: inline-block;
  top: -180px;
  left: 0;
  width: 70px;
  height: 100%;
  background-color: #fff;
  transition: 300ms;
  animation: shinyshiny 4s ease-in-out 1;
}

@-webkit-keyframes shinyshiny {
  0% {
    -webkit-transform: scale(0) rotate(45deg);
    opacity: 0;
  }
  80% {
    -webkit-transform: scale(0) rotate(45deg);
    opacity: 0.5;
  }
  81% {
    -webkit-transform: scale(4) rotate(45deg);
    opacity: 1;
  }
  100% {
    -webkit-transform: scale(50) rotate(45deg);
    opacity: 0;
  }
}
</style>