<template>
  <div class="home-aboutus">
    <UiHeading :h="'2'" :type="'E3'" :text="'選ばれる理由'" />
    <HomeReasonReason1 class="space-btm-200p"/>
    <HomeReasonReason2  class="space-btm-200p"/>
    <HomeReasonReason3 />
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import UiHeading from "@/components/Ui/Heading.vue";
import HomeReasonReason1 from "@/components/Home/Reason/Reason1.vue";
import HomeReasonReason2 from "@/components/Home/Reason/Reason2.vue";
import HomeReasonReason3 from "@/components/Home/Reason/Reason3.vue";

export default {
  name: "HomeAboutUs",
  components: {
    UiHeading,
    HomeReasonReason1,
    HomeReasonReason2,
    HomeReasonReason3,
  },
  props: {},
  data() {
    return {};
  },
  computed: {
    ...mapGetters(["rootUrl"]),
  },
  watch: {},
  methods: {},
};
</script>

<style lang="scss" scoped>
</style>