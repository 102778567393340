import Vue from 'vue';
import Vuetify from 'vuetify/lib/framework';

Vue.use(Vuetify);

export default new Vuetify({
    theme: {
        themes: {
            light: {
                main: '#3e3a39',
                sub: '#171c61',
                sub2: '#35a7db'
            },
        },
    },
    options: {
        customProperties: true
    }
});
