<template>
  <div class="home-reason-reason1">
    <UiBar3
      :type="'C'"
      :barText="'ウェブの集客・マーケティングに関する実績'"
      :imgPath="'GH-syoukai-top-shikaku02.png'"
      style="margin-bottom: 30px"
    />

    <p>
      弊社は岐阜にありながら、集客やマーケティングにおいて全国的な実績を保有しているのが大きな特徴です。「依頼を受けたものをただ作るだけでなく、ホームページを通して集客やマーケティングによって売上を増やす」ことができるのが弊社の強みです。
    </p>

    <div class="cover">
      <div class="left">
        <div class="sub-title">
          <p>
            ネット通販における、完全成功報酬型の販売代行にて、手掛けた商品全てで、売上アップ・販売数増加に成功
          </p>
        </div>
        <img
          alt="クライアント目線での柔軟なご提案"
          src="@/assets/GH-syoukai-top-007.jpg"
        />
      </div>
      <div class="container ratio-1_1">
        <img
          alt="クライアント目線での柔軟なご提案"
          src="@/assets/GH-syoukai-ira-003-1.png"
          class="image"
        />
        <img
          alt="クライアント目線での柔軟なご提案"
          src="@/assets/GH-syoukai-ira-003-2.png"
          class="image"
        />
      </div>
    </div>
    <p class="note">
      売れなければ報酬をいただけないという条件での販売代行で長年結果を出し続けている希少な業者です。
    </p>

    <div class="sub-title">
      <p>
        マーケティングの難易度が高いとされる学習系商材の販売代行で<br
          class="real-pc-only"
        />販売数日本一を多数獲得しています！
      </p>
    </div>
    <div class="logo-cover">
      <div class="logos">
        <img
          alt="スタディサプリロゴ"
          src="@/assets/GH-syoukai-top-logo-001.jpg"
        />
        <p>リクルート社の<br class="tb-only">人気のアプリ</p>
      </div>
      <div class="logos">
        <img alt="七田式教育ロゴ" src="@/assets/GH-syoukai-top-logo-002.jpg" />
        <p>世界37万人体験の<br class="tb-only">有名教材</p>
      </div>
      <div class="logos">
        <img alt="ユーキャンロゴ" src="@/assets/GH-syoukai-top-logo-003.jpg" />
        <p>資格のユーキャン</p>
      </div>
      <div class="logos">
        <img alt="レアジョブロゴ" src="@/assets/GH-syoukai-top-logo-004.jpg" />
        <p>オンライン英会話<br class="tb-only">シェア1位</p>
      </div>
      <div class="logos">
        <img
          alt="ネイティブイングリッシュロゴ"
          src="@/assets/GH-syoukai-top-logo-005.jpg"
        />
        <p>英語教材シェア日本一</p>
      </div>
    </div>
    <div class="cover">
      <div class="left">
        <div class="sub-title">
          <p>
            インターネットマーケティングに関するセミナーで、<br class="real-pc-only">日本全国の都市で40回以上の登壇実績
          </p>
        </div>
        <p class="seminar">
          セミナー開催地：<br class="sp-only">東京・大阪・名古屋・福岡・札幌・仙台・神戸・広島・松山・鹿児島・沖縄
        </p>
        <p class="seminar">
          セミナーの内容：<br class="sp-only">インターネット集客・ウェブコンテンツ制作・SEO対策
        </p>
      </div>
      <div class="container ratio-308_251">
        <img
          alt="セミナーイメージ"
          src="@/assets/GH-syoukai-ira-004-1.png"
          class="image"
        />
        <img
          alt="セミナーイメージ"
          src="@/assets/GH-syoukai-ira-004-2.png"
          class="image"
        />
      </div>
    </div>
    <div class="image-cover">
      <img alt="セミナー風景1" src="@/assets/GH-syoukai-top-003.jpg" />
      <img alt="セミナー風景1" src="@/assets/GH-syoukai-top-004.jpg" />
      <img alt="セミナー風景1" src="@/assets/GH-syoukai-top-005.jpg" />
      <img alt="セミナー風景1" src="@/assets/GH-syoukai-top-006.jpg" />
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import UiBar3 from "@/components/Ui/Bar3.vue";

export default {
  name: "HomeAboutUs",
  components: {
    UiBar3,
  },
  props: {},
  data() {
    return {};
  },
  computed: {
    ...mapGetters(["rootUrl"]),
  },
  watch: {},
  methods: {},
};
</script>

<style lang="scss" scoped>
.home-reason-reason1 {
  .cover {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    margin-top: 30px;
        @media screen and (min-width:768px) and ( max-width:1024px){
      align-items: center;
    }
    @media screen and (max-width: 767px) {
      flex-direction: column;
      padding-bottom: 30px;
    }
    .left {
      width: 60%;
      @media screen and (max-width: 767px) {
        width: 100%;
      }
      .seminar {
        font-size: 15px;
        @media screen and (max-width: 767px) {
          font-size: 18px;
          line-height: 1.8em;
          
        }
      }
      .seminar:nth-of-type(2){
        @media screen and (max-width: 767px){
          margin-top: 20px;
        }
      }
    }
    .container {
      width: 28%;
      @media screen and (max-width: 767px) {
        width: 60%;
        margin-top: 30px;
      }
    }
  }

  .note {
    margin-bottom: 100px !important;
  }
  .logo-cover {
    display: flex;
    justify-content: space-between;
    margin-bottom: 100px;
    @media screen and (max-width: 767px) {
      flex-direction: column;
    }
    .logos {
      width: 17%;
      display: flex;
      flex-direction: column;
      align-items: center;
      @media screen and (max-width: 767px) {
        width: 100%;
      }

      img {
        height: 120px;
        object-fit: contain;
      }
      p {
        font-size: 14px;
      @media screen and (min-width:768px) and ( max-width:1024px) {
          font-size: 12px;
          line-height: 1.6em;
          text-align: center;
          display: flex;
          align-items: center;
          justify-content: center;
          height: 50px;
        }
      }
    }
  }
  .image-cover {
    margin-top: 50px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    @media screen and (max-width: 767px) {
      flex-wrap: wrap;
      margin-top: 0;
    }
    img {
      width: 23%;
      @media screen and (max-width: 767px) {
        width: 48%;
        margin-bottom: 20px;
      }
    }
  }
  .sub-title {
    border-left: 20px var(--main) solid;
    color: var(--main);
    font-size: 24px;
    font-weight: bold;
    padding-top: 15px;
    padding-bottom: 15px;
    margin-bottom: 30px;
    @media screen and (max-width: 1024px) {
      border-left: 10px var(--main) solid;
      font-size: 19px;
      line-height: 1.3em;
    }

    p {
      padding-left: 20px;
      @media screen and (max-width: 767px) {
        padding-left: 8px;
      }
    }
  }
}
</style>