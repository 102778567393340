<template>
  <div class="home-reason-reason3">
    <UiBar3
      :type="'C'"
      :barText="'幅広い業務に対応できるから取引のメリットが多い'"
      :imgPath="'GH-syoukai-top-shikaku03.png'"
      style="margin-bottom:30px;"
    />

    <p class="des">
    弊社はホームページ制作からネットショップの制作、システム開発、紙媒体の制作、広告運用や集客代行まで、ITに関することなら、幅広い案件に対応可能です。そのため、クライアントには以下のメリットがあります。
    </p>


      <div class="text">
        <ul>
          <li>業者を1本化できるので、案件によって発注先を変更しなくていい、業者をさがさなくていい</li>
          <li>業者を1本化できるので、打ち合わせの工数を低減できる（業者が変われば、その都度、業者選定と詳細な打ち合わせが必要になる）</li>
          <li>
            制作物を流用することによるコストメリット（例：ホームページの内容を流用することで、チラシをコストダウンして制作できる）
          </li>
        </ul>

      </div>
      <div class="container ratio-55_13 pc-only">
        <img
          alt="幅広い業務に対応イメージ1"
          src="@/assets/GH-syoukai-ira-005-1.png"
          class="image"
        />
        <img
          alt="幅広い業務に対応2"
          src="@/assets/GH-syoukai-ira-005-2.png"
          class="image"
        />
      </div>
      <div class="container ratio-16_9 sp-only">
        <img
          alt="幅広い業務に対応イメージ1"
          src="@/assets/GH-syoukai-ira-005sp-1.png"
          class="image"
        />
        <img
          alt="幅広い業務に対応2"
          src="@/assets/GH-syoukai-ira-005sp-2.png"
          class="image"
        />
      </div>
    </div>

</template>

<script>
import { mapGetters } from "vuex";
import UiBar3 from "@/components/Ui/Bar3.vue";

export default {
  name: "HomeReasonReason3",
  components: {
    UiBar3,
  },
  props: {},
  data() {
    return {};
  },
  computed: {
    ...mapGetters(["rootUrl"]),
  },
  watch: {},
  methods: {},
};
</script>

<style lang="scss" scoped>
.home-reason-reason3 {
    .des{
      margin-bottom: 50px!important;
    }
    .text {
      // width: 62%;
      ul{
        list-style-type: disc;
        padding-bottom: 30px;
      }
    }
   .container {
    height: 260px;
    @media screen and (max-width: 767px) {
      // height: 90px;
    }
    .image{
      @media screen and (min-width:768px) and ( max-width:1024px){
         border-bottom: 1px #e7e7e7 solid;
       }
    }
    }
}
</style>