<template>
  <v-app>
    <Header />
    <v-main>
      <router-view class="body-top" />
      <ToTopButton :path="path" />
    </v-main>
    <Footer :path="path" />
  </v-app>
</template>

<script>
import { mapGetters } from "vuex";
import "reset-css";
import Header from "@/components/Layout/Header.vue";
import ToTopButton from "@/components/Ui/ToTopButton.vue";
import Footer from "@/components/Layout/Footer.vue";

export default {
  name: "App",
  data() {
    return {};
  },
  components: {
    Header,
    Footer,
    ToTopButton,
  },
  computed: {
    ...mapGetters(["rootUrl"]),
    path() {
      return this.$route.path;
    },
  },
};
</script>
<style lang="scss">
@import url("https://fonts.googleapis.com/css2?family=Noto+Sans+JP:wght@400;500;700&display=swap");

#app {
  font-family: "Noto Sans JP", sans-serif;
  font-size: 18px;
  color: #3e3a39;
}

:root {
  --main: #171c61;
  --sub: #35a7db;
  --sub2: #3e3a39;
}

html * {
  box-sizing: border-box !important;
}

img {
  max-width: 100%;
  height: auto;
  image-rendering: -webkit-optimize-contrast;
}

.wrap {
  width: 100vw; //画面幅に対して、基本100%
  max-width: 1100px; //でも、1150pxを超える画面幅でwidth: 100%では困るのでこの設定
  // width: 1150px;
  margin: 0 auto;
  line-height: 2.2em;

  @media screen and (max-width: 1100px) {
    padding-left: 20px;
    padding-right: 20px;
  }
}
.wrap2 {
  width: 100vw; //画面幅に対して、基本100%
  max-width: 1600px; //でも、1150pxを超える画面幅でwidth: 100%では困るのでこの設定
  // width: 1150px;
  margin: 0 auto;
  line-height: 2.2em;
  @media screen and (max-width: 1600px) {
    padding-left: 20px;
    padding-right: 20px;
  }
}

.body-top {
  margin-top: 70px;
  @media screen and (max-width: 767px) {
    margin-top: 60px;
  }
}

.sp-only {
  display: none;
  @media screen and (max-width: 767px) {
    display: initial !important;
  }
}

.tb-only {
  display: none;
  @media screen and (min-width:768px) and ( max-width:1024px) {
    display: initial !important;
  }
}
.sp-tb-only {
  display: none;
  @media screen and (max-width: 1024px) {
    display: initial !important;
  }
}

.pc-only {
  @media screen and (max-width: 767px) {
    display: none;
  }
}
.real-pc-only {
  @media screen and (max-width: 1024px) {
    display: none;
  }
}

h1,
h2 {
  font-size: 32px;
  font-weight: 500;
  color: var(--sub);
  // text-align: center;
}
h3 {
  font-size: 22px;
}
h4 {
  font-size: 18px;
}
.v-application p {
  margin-bottom: 0 !important;
}
a {
  text-decoration: none;
}

//ヘッダー用のスペースレンジャー
.space-top-70 {
  padding-top: 70px;
  @media screen and (max-width: 767px) {
    padding-top: 60px;
  }
}
//共通スペースレンジャー
.space-btm-200 {
  margin-bottom: 200px;
  @media screen and (max-width: 767px) {
    margin-bottom: 100px;
  }
}
.space-btm-200p {
  padding-bottom: 200px;
  @media screen and (max-width: 767px) {
    padding-bottom: 100px;
  }
}
.space-top-130 {
  margin-top: 130px;
  @media screen and (max-width: 767px) {
    margin-top: 60px;
  }
}
.space-top-150 {
  margin-top: 150px;
  @media screen and (max-width: 767px) {
    margin-top: 80px;
  }
}

.container {
  max-width: 100%;
  margin: 0 auto;
  position: relative;
  height: auto;
}
.ratio-16_9:before {
    content: "";
    display: block;
     padding-top: 56.25%;  /* 16:9*/
}
.ratio-55_13:before {
    content: "";
    display: block;
     padding-top: 0.47%;  /* 1100:260 */
}
.ratio-100:before {
    content: "";
    display: block;
     padding-top: 10%;  /* 1100:260 */
     @media screen and (max-width: 1100px) {
      padding-top:13%;
     }
}
.ratio-308_251:before {
    content: "";
    display: block;
     padding-top: 81.5%;  /* 308:251 */
}
.ratio-200:before {
    content: "";
    display: block;
     padding-top: 250%;  /* tokusyu*/

}
.ratio-1_1:before {
    content: "";
    display: block;
     padding-top: 100%;  /* 1:1 */
}

.container img {
  position: absolute;
  top:0;
  left: 0;
  height: 100%;
  object-fit: contain;
  -webkit-animation: tikutaku 2s linear infinite 0s forwards;
          animation: tikutaku 2s linear infinite 0s forwards;
}

.container img:nth-child(1) {
  z-index: 2;
  visibility: visible;
}

.container img:nth-child(2) {
  -webkit-animation-delay: -1s;
          animation-delay: -1s;
  z-index: 0;
  visibility: hidden;
}

@-webkit-keyframes tikutaku {
  0% {
    visibility: visible;
    z-index: 2;
  }
  50.5% {
    visibility: visible;
    z-index: 2;
  }
  51% {
    visibility: hidden;
    z-index: 0;
  }
  100% {
    visibility: hidden;
    z-index: 0;
  }
}

@keyframes tikutaku {
  0% {
    visibility: visible;
    z-index: 2;
  }
  50.5% {
    visibility: visible;
    z-index: 2;
  }
  51% {
    visibility: hidden;
    z-index: 0;
  }
  100% {
    visibility: hidden;
    z-index: 0;
  }
}


</style>


