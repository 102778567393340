<template>
  <div class="home-contact wrap">
    <UiHeading :h="'2'" :type="'B5'" :text="'お電話でのお問い合わせ'" id="contact-tel"/>
    <div class="guide">
      <p class="head">
        お電話にて「ホームページ制作の初回相談または簡易提案を希望」とお伝えください。
      </p>
      <a href="tel:0582149005"><p class="tel">058-214-9005</p></a>
      <p class="small">（受付時間:平日10時 ～ 18時）</p>
    </div>

    <UiHeading :h="'2'" :type="'B5'" :text="'LINEでのお問い合わせ'" id="contact-line"/>
    <div class="guide">
      <p class="head">
        株式会社グレイトヘルプのLINEを友だち追加して、「ホームページ制作の初回相談」を希望とお伝えください。
      </p>
      <p class="small">以下のボタンから<br class="sp-only">友だち追加ができます。</p>

      <div class="c-button _shiny is-bounce" style="margin: 20px auto">
        <a
          href="https://line.me/ti/p/FeIG5taxcP"
          target="_blank"
          rel="noopener noreferrer"
        >
          <img
            alt="LINEアイコン"
            src="@/assets/line_button.png"
            class="button2-btn"
        /></a>
      </div>
    </div>
    <div class="guide">
      <p class="small">以下のQRコードを読み取ることで友だち追加できます。</p>
      <img alt="LINEコード" src="@/assets/LineOR.png" class="line-qr" />
    </div>

    <UiHeading :h="'2'" :type="'B5'" :text="'ウェブでのお問い合わせ'" id="contact-mail"/>
    <div class="guide">
      <p>
        以下のフォームに必要事項を記入して、送信をお願いします。
      </p>
    </div>
    <HomeContactContactForm
      class="space-btm-200p"
      style="background-color: #f2f2f2"
    />

  </div>
</template>

<script>
import { mapGetters } from "vuex";
import UiHeading from "@/components/Ui/Heading.vue";
import HomeContactContactForm from "@/components/Home/Contact/ContactForm.vue";

export default {
  name: "HomeAboutUs",
  components: {
    UiHeading,
    HomeContactContactForm,
  },
  props: {},
  data() {
    return {
      pageType: 0,
    };
  },
  computed: {
    ...mapGetters(["rootUrl"]),
  },
  watch: {},
  methods: {},
};
</script>

<style lang="scss" scoped>
// .home-contact{
.guide {
  text-align: center;
  color: var(--main);
  margin-top: 20px;
  margin-bottom: 80px;
  .head{
    @media screen and (max-width: 767px){
      padding-bottom: 30px;
    }
  }

  a {
    color: var(--main) !important;
  }
  .tel {
    font-size: 60px;
    font-weight: bold;
    margin-top: 30px;
    color: var(--main) !important;
    @media screen and (max-width: 767px) {
      font-size: 45px;
    }
  }
  .small {
    font-size: 22px;
    margin-top: 20px;
  }
  .line-qr {
    width: 200px;
  }
  @media (min-width: 751px) {
    a[href*="tel:"] {
      pointer-events: none;
      cursor: default;
    }
  }
}
.bottom {
  height: 200%;
  padding-top: 100px;
  @media screen and (max-width: 767px) {
    padding-top: 50px;
  }
  .container {
    width: 100%;
    img{
      vertical-align: bottom;
      width: 100%;
    }

  }
}
// }
//newlineボタン
.c-button._shiny {
  margin-top: 30px;
  margin-bottom: 10px;
  display: block;
  position: relative;
  max-width: 250px;
  color: #fff;
  text-align: center;
  text-decoration: none;
  overflow: hidden;
  transition: 300ms;
}
.c-button._shiny::before {
  position: absolute;
  content: "";
  display: inline-block;
  top: -180px;
  left: 0;
  width: 30px;
  height: 100%;
  background-color: #fff;
  transition: 300ms;
  animation: shinyshiny 4s ease-in-out infinite;
}

.c-button._shiny:hover {
  text-decoration: none;
  color: #fff;
  box-shadow: none;
  opacity: 0.7;
  -webkit-transform: translateY(3px);
}

@-webkit-keyframes shinyshiny {
  0% {
    -webkit-transform: scale(0) rotate(45deg);
    opacity: 0;
  }
  80% {
    -webkit-transform: scale(0) rotate(45deg);
    opacity: 0.5;
  }
  81% {
    -webkit-transform: scale(4) rotate(45deg);
    opacity: 1;
  }
  100% {
    -webkit-transform: scale(50) rotate(45deg);
    opacity: 0;
  }
}

/* ボタンをバウンドさせる */
.is-bounce {
  animation: bounce 4s infinite;
  -moz-animation: bounce 4s infinite;
  -webkit-animation: bounce 4s infinite;
  -webkit-animation-delay: 4s;
  animation-delay: 2s;
}
@-webkit-keyframes bounce {
  0%,
  4%,
  10%,
  18%,
  100% {
    -webkit-transform: translateY(0);
  }
  5% {
    -webkit-transform: translateY(-6px);
  }
  12% {
    -webkit-transform: translateY(-4px);
  }
}
@keyframes bounce {
  20%,
  24%,
  30%,
  34%,
  100% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }
  25% {
    -webkit-transform: translateY(-6px);
    transform: translateY(-6px);
  }
  32% {
    -webkit-transform: translateY(-4px);
    transform: translateY(-4px);
  }
}
/* アニメーションを遅延させる */
.is-bounce + .is-bounce {
  -webkit-animation-delay: 0.5s;
  animation-delay: 0.5s;
}
.line-top-100 {
  margin-top: 100px;
}
.line-top-50 {
  margin-top: 50px;
}
.line-bottom-100 {
  margin-bottom: 100px;
}
</style>