<template>
  <div class="home">
    <div class="top">
      <img
        alt="RobecityInternationalトップ"
        src="@/assets/GH-syoukai-top-image001.jpg"
        class="pc-only"
      />
      <img
        alt="RobecityInternationalトップ"
        src="@/assets/GH-syoukai-top-image-SP001.jpg"
        class="sp-only"
      />
    </div>

      <div class="space-top-70">
        <HomeExample class="space-top-130" />
      </div>

      <div class="space-top-70">
        <HomeAboutUs class="space-top-130" />
      </div>

      <div class="space-top-70">
        <HomeReason class="space-top-130 wrap" />
      </div>

      <div class="space-top-70-this-only">
        <HomeHelpYou class="space-top-130-this-only" />
      </div>

      <div class="space-top-70">
        <HomeContact/>
      </div>

          <div class="bottom">
      <div class="container ratio-100 pc-only">
        <img
          alt="街並みイメージ1"
          src="@/assets/GH-syoukai-ira-008-1.png"
          class="image"
        />
        <img
          alt="街並みイメージ2"
          src="@/assets/GH-syoukai-ira-008-2.png"
          class="image"
        />
      </div>
      <div class="container ratio-100 sp-only">
      <img
          alt="街並みイメージ1"
          src="@/assets/GH-syoukai-ira-008sp-1.png"
          class="image"
        />
        <img
          alt="街並みイメージ2"
          src="@/assets/GH-syoukai-ira-008sp-2.png"
          class="image"
        />
      </div>
    </div>

      
  </div>
</template>

<script>
import { mapGetters } from "vuex";
// @ is an alias to /src
import HomeExample from "@/components/Home/Example.vue";
import HomeAboutUs from "@/components/Home/AboutUs.vue";
import HomeReason from "@/components/Home/Reason.vue";
import HomeHelpYou from "@/components/Home/HelpYou.vue";
import HomeContact from "@/components/Home/Contact.vue";

export default {
  name: "Home",
  components: {
    HomeExample,
    HomeAboutUs,
    HomeReason,
    HomeHelpYou,
    HomeContact,
  },
  props: {},
  data() {
    return {};
  },
  computed: {
    ...mapGetters(["rootUrl"]),
  },
  watch: {},
  methods: {},
  created() {},
};
</script>
<style lang="scss" scoped>
.space-top-70-this-only {
  padding-top: 70px;
  @media screen and (max-width: 767px) {
    padding-top: 0px;
  }
}
.space-top-130-this-only{
    margin-top: 130px;
  @media screen and (max-width: 767px) {
    margin-top: 0px;
  }
}
.bottom {
  height: 200%;
  padding-top: 100px;
  @media screen and (max-width: 1100px) {
    padding-top: 30px;
  }
  @media screen and (max-width: 767px) {
    height: 150px;
    display: flex;
    align-items: flex-end;
  }
  .container {
    width: 100%;
    @media screen and (max-width: 767px) {
      height: 150px;
    }
    img{
      vertical-align: bottom;
      width: 100%;
      @media screen and (max-width: 767px) {
        height: 200px;
        bottom: 0;
      }
    }

  }
}
</style>
