<template>
  <div class="ui-box">
    <div class="top-title" v-if="numberImgPath !== ''">
      <img
        :src="require(`@/assets/${numberImgPath}`)"
        :alt="{ numberImgName }"
        class="no poyopoyo"
      />
      <h3 v-html="boxTitle"></h3>
    </div>
    <div class="cover">
      <div class="top">
        <img
          :src="require(`@/assets/${imgPath}`)"
          :alt="{ imgName }"
          class="photo"
        />
        <div class="table-cover">
          <UiTable :tableList="tableList" class="table" />
          <a :href="`${siteUrl}`" target="_blank" rel="noopener noreferrer">
            <div class="button">
              <p>ホームページを見てみる</p>
            </div></a
          >
        </div>
      </div>
      <div class="bottom">
        <div class="issue">
          <h4 class="title">&#91;{{ title1 }}&#93;</h4>
          <p class="des">{{ des1 }}</p>
        </div>
        <div class="issue">
          <h4 class="title">&#91;{{ title2 }}&#93;</h4>
          <p class="des">{{ des2 }}</p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import UiTable from "../../Ui/Table.vue";
export default {
  name: "UiBox",
  components: {
    UiTable,
  },
  props: {
    boxTitle: String,
    numberImgPath: { type: String, default: "" },
    numberImgName: { type: String, default: "" },
    imgPath: String,
    imgName: String,
    title1: String,
    title2: String,
    des1: String,
    des2: String,
    siteUrl: String,
    tableList: Array,
  },
  data() {
    return {};
  },
  //   mounted() {
  //   window.addEventListener("scroll", this.getScroll)
  // },
  computed: {
    ...mapGetters(["rootUrl"]),
  },
  //   beforeDestroy() {
  //   window.removeEventListener("scroll", this.handleScroll);
  // },
  // created() {
  //   window.addEventListener("scroll", this.handleScroll);
  // },
  methods: {
    //   handleScroll() {
    //     if (this.scrollTimer === null) {
    //       this.scrollTimer = setTimeout(
    //         function () {
    //           this.scrollY = window.scrollY;
    //           clearTimeout(this.scrollTimer);
    //           this.scrollTimer = null;
    //           // console.log(this.scrollY);
    //         }.bind(this),
    //         200
    //       );
    //     }
    //   },
    //    getScroll() {
    //     this.scrollY = window.scrollY
    //     console.log(this.scrollY);
    // }
  },
};
</script>

<style lang="scss" scoped>
.ui-box {
  .top-title {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 80px;
    h3 {
      color: var(--main);
      font-size: 32px;
      @media screen and (max-width: 767px) {
        font-size: 20px;
        text-align: center;
      }
    }
    .no {
      width: 100px;
      margin-right: 3%;
      @media screen and (max-width: 767px) {
        width: 70px;
        margin-right: 1%;
      }
    }
  }
  .cover {
    .top {
      display: flex;
      align-items: flex-start;
      justify-content: space-between;
      @media screen and (max-width: 767px) {
        flex-direction: column;
      }
      .photo {
        width: 48%;
        // height: 300px;
        top: 0;
        left: 0;
        @media screen and (min-width: 768px) and (max-width: 1600px) {
        }
        @media screen and (max-width: 767px) {
          width: 100%;
        }
      }
      .table-cover {
        width: 48%;
        @media screen and (max-width: 767px) {
          width: 100%;
          margin-top: 30px;
        }
        ::v-deep.table {
          width: calc(100% - 250px);
          @media screen and (min-width: 1100px) and (max-width: 1600px) {
            width: 550px;
          }
          @media screen and (max-width: 1099px) {
            width: 100%;
          }
          // font-size: 18px !important;

          th {
            width: 35%;
            color: var(--main);
            padding: 10px 0;
            @media screen and (max-width: 767px) {
              width: 100%;
            }
          }
          td {
            line-height: 1.8em;
            // font-size: 17px;
            padding: 10px 0;
          }
        }
        a {
          transition: 0.5s;
        }
        a:hover {
          letter-spacing: 0.3rem;
          transition: 0.5s;
          .button {
            background-color: var(--sub);

            p {
              color: white;
            }
          }
        }
        .button {
          width: calc(100% - 250px);
          border: 1px solid var(--sub);
          margin-top: 40px;
          padding: 8px 0;
          @media screen and (min-width: 1100px) and (max-width: 1600px) {
            width: 550px;
          }
          @media screen and (max-width: 1099px) {
            width: 80%;
            margin-right: auto;
            margin-left: auto;
          }
          p {
            color: var(--sub);
            text-align: center;
          }
        }
      }
    }
  }
  .bottom {
    width: 100vw; //画面幅に対して、基本100%
    max-width: 1100px; //でも、1150pxを超える画面幅でwidth: 100%では困るのでこの設定
    // width: 1150px;
    margin: 0 auto;
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    @media screen and (min-width: 768px) and (max-width: 1300px){
      margin-top: 30px;
    }
    @media screen and (min-width: 768px) and (max-width: 1099px){
      width: 100%;
    }
    @media screen and (max-width: 767px) {
      display: block;
      width: 100%;
    }

    .issue {
      width: 48%;
      @media screen and (max-width: 767px) {
        width: 100%;
        margin-top: 50px;
      }
      .title {
        text-align: center;
        color: var(--sub);
        margin-bottom: 10px;
      }
    }
  }
}

.poyopoyo {
  animation: poyopoyo 1.5s ease-out infinite;
  opacity: 1;
}
@keyframes poyopoyo {
  0%,
  40%,
  60%,
  80% {
    transform: scale(1);
  }
  50%,
  70% {
    transform: scale(0.9);
  }
}
</style>