import Vue from 'vue'
import VueRouter from 'vue-router'
import store from '@/store'
import {
  setTitle,
  setDescription
} from '@/mixins/index.js'



//ここからページ
import Home from '../views/Home.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home,
    // redirect: '/fl/',
    meta: {
      title: '株式会社グレイトヘルプ',
      description: '株式会社グレイトヘルプの実績を公開しています。'
    }
  },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition || to.meta.savedPosition == true) {
      return savedPosition
    } else {
      return {
        x: 0,
        y: 0
      }
    }
  }
})

router.beforeEach((to, from, next) => {
  setTitle(to.meta.title);
  setDescription(to.meta.description);
  store.dispatch("setRootUrl");

  next();
  });

export default router
