<template>
  <div class="top-button" ontouchstart="">
    <div
      class="to-top-button"
      :class="{ 'over-baseline': scrollY >= 500 }"
      v-scroll-to="{
        el: '#app',
        container: 'body',
        duration: 750,
        easing: 'ease',
        offset: 0,
      }"
    >
      <div class="top">
         <img src="@/assets/GH-syoukai-top-pagetop.png" />
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  name: "UiToTopButton",
  props: {
    path: String,
  },
  data() {
    return {
      scrollY: 0,
      scrollTimer: null,
    };
  },
  computed: {
    ...mapGetters(["rootUrl"]),
  },
  beforeDestroy() {
    window.removeEventListener("scroll", this.handleScroll);
  },
  created() {
    window.addEventListener("scroll", this.handleScroll);
  },
  methods: {
    handleScroll() {
      //スクロールすると実行される
      if (this.scrollTimer === null) {
        //初期値はnull
        this.scrollTimer = setTimeout(
          //ここでnullじゃなくてsetTimeoutになる
          function () {
            //実行するセット
            this.scrollY = window.scrollY; //ウィンドウトップからの画面距離
            clearTimeout(this.scrollTimer); //scrollTimerをリセット
            this.scrollTimer = null; //初期値に戻す
          }.bind(this), //第一引数
          200 //第二引数(ミリ秒後)←0.2秒後にはじまるっていう意味
        );
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.to-top-button {
  display: none;
  position: fixed;
  width: 60px;
  height: auto;
  z-index: 999;
  right: 0%;
  bottom: 3%;
  cursor: pointer;
  opacity: 0.7;
  // border: 2px white solid;

  @media screen and (max-width: 767px) {
    width: 45px;
    opacity: 0.8;
    bottom: 9%;
  }

  &:hover {
    opacity: 1;
  }
}

.top {
  width: 20px;
  // background-color: (--main);
  // background-color: var(--main);
  // background-blend-mode: darken;
  // color: var(--main);
  //  filter: invert(70%) sepia(81%) saturate(580%) hue-rotate(300deg) brightness(106%) contrast(110%);
}


.over-baseline {
  display: initial;
  display: flex;
  justify-content: center;
  align-items: center;
}
</style>