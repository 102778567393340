<template>
  <div class="home-example">
    <UiHeading :h="'1'" :type="'E3'" :text="'制作事例'" />
    <HomeExampleBox
      :boxTitle="`売上や集客アップにつながる<br class='sp-only'>ホームページ`"
      :numberImgPath="`GH-syoukai-top-fukidashi001.png`"
      :numberImgName="`no1`"
      :imgPath="`GH-syoukai-top-monita002.jpg`"
      :imgName="`売上や集客アップにつながるホームページ`"
      :title1="`クライアントの希望や悩み`"
      :title2="`制作のポイント`"
      :des1="`これまで依頼していた業者は、ホームページを作ってから、売上や集客に関する業務を何もしてくれなかったので、ただホームページを持っているだけになってしまっていた。こちらが質問しても答えてくれないことに強い不安があった。当院のある流山市は、人口増加率日本一のエリアで競合医院もどんどん増えているので、その中で勝てるようなホームページを作りたかったし、集客などの相談を信頼してお願いできる業者と付き合いたかった。`"
      :des2="`弊社の実績を期待して千葉県からお問い合わせをいただきました。歯医者のホームページはこれまで多数制作しているので、結果の出ているマーケティングを適用しました。クライアントの事業の魅力をホームページ上ですべて伝え、かつ、閲覧者にとってわかりやす構成にしました。検索エンジンでも上位表示し、医院の集客数は過去最高を達成。毎月の集客業務も依頼をいただき、さらなる売上アップを実現し、2年連続で過去最高売上を更新しています。`"
      :siteUrl="`https://no4618.com/`"
      :tableList="tableList1"
      class="wrap2"
    />
    <HomeExampleRankBox
    :comentImgPath="`GH-syoukai-top-kensaku002.png`"
    :comentImgPathSpOnly="`GH-syoukai-top-kensaku002sp.png`"
    :comentImgName="`「地域名×業種名」の検索ワードで検索結果の上位を獲得！`"
    :resultImgPath="`GH-syoukai-top-kensaku003.png`"
    :description="`当医院のある「流山」「流山市」「おおたかの森」「流山おおたかの森」という地域名と、「歯科」「歯科医」「歯医者」「矯正」「矯正歯科」という業種名のかけ合わせの検索キーワードで、検索結果の1～6位以内に表示されています。※ホームページの制作とは別途のSEOサービスをご契約いただいています。`" class="space-top-130 space-btm-200"
    />
    <hr class="separation">

    <HomeExampleBox
      :boxTitle="`売上や集客アップにつながるホームページ`"
      :imgPath="`GH-syoukai-top-monita001.jpg`"
      :imgName="`売上や集客アップにつながるホームページ`"
      :title1="`クライアントの希望や悩み`"
      :title2="`制作のポイント`"
      :des1="`事務所の独立に際してホームページが必要になりました。長年、株式会社グレイトヘルプ様の顧問をしていて、ウェブの実績などを把握していたため、迷わず依頼することにしました。実力を知っているので、「集客を強化したい」という希望のみで、完全おまかせでホームページの提案をお願いしました。英語ページの対応の対応もできるとのことだったので、この点も良かったです。`"
      :des2="`法律に詳しくない人が閲覧しても、取扱案件の内容や相談のイメージが持てるように、「わかりやすさ」を意識して目線を落として制作しました。また、相談内容に関連する検索キーワードで多数上位表示することもできました。結果として、独立前の弁護士事務所のホームページからお問い合わせ数は3倍以上になっています。英語ページもしっかりと制作し、海外および外国人からのアクセスも獲得しています。`"
      :siteUrl="`https://nagoya-intlaw.com/`"
      :tableList="tableList2"
      class="space-top-130 wrap2"
    />
    <HomeExampleRankBox
    :comentImgPath="`GH-syoukai-top-kensaku004.png`"
    :comentImgPathSpOnly="`GH-syoukai-top-kensaku004sp.png`"
    :comentImgName="`「地域名×業種名」「地域名×案件名」の検索キーワードで検索結果の上位を獲得！`"
    :coment2ImgPath="`GH-syoukai-top-kensaku005.png`"
    :coment2ImgPathSpOnly="`GH-syoukai-top-kensaku005sp.png`"
    :resultImgPath="`GH-syoukai-top-kensaku006.png`"
    :description="`当事務所のある「名古屋」という地域名と、「国際弁護士」という業種名、「国際離婚」「国際相続」「国際取引」「難民事件」などのかけ合わせの多数の検索キーワードで、検索結果の1～10位以内に表示されています。英語でも検索結果の上位に表示され、外国人のクライアントの集客もできています。※ホームページの制作とは別途のSEOサービスをご契約いただいています。`"
    class="space-top-130 space-btm-200 fs"
    />

    <div style="background-color: #f2f2f2" class="space-top-70">
      <HomeExampleBox
        :boxTitle="`見栄えの良い、イメージアップにつながるホームページ`"
        :numberImgPath="`GH-syoukai-top-fukidashi02.png`"
        :numberImgName="`no2`"
        :imgPath="`GH-syoukai-top-monita003.jpg`"
        :imgName="`見栄えの良い、イメージアップにつながるホームページ`"
        :title1="`クライアントの希望や悩み`"
        :title2="`制作のポイント`"
        :des1="`自分たちの料理の良さをお客様に伝えたいので、見栄えの良いホームページを作りたい。友人の店舗が動画のあるホームページを作り、とても見た目が良いので、動画を用いたホームページを作りたい。定休日などの告知を自分たちでできるようにしたい。ホームページ上にメニューなどをすべて掲載することで、電話での問い合わせの対応を減らしたい。`"
        :des2="`ホームページのトップに画面いっぱいサイズで動画を掲載することで、臨場感のあるホームページにしました。また、ところどころに動くデザインを実装することで、見ている人をひきつけるようにしました。食べログなどでは掲載できない、店舗の魅力もふんだんに伝えられるよう写真を多用し、店舗の良さをありのまま伝えるようにしました。`"
        :siteUrl="`https://takumi-hirano.com/`"
        :tableList="tableList3"
        class="space-top-130 wrap2 space-btm-200p"
      />
    </div>

    <HomeExampleBox
      :boxTitle="`業務の手間を削減する機能やシステムを搭載したホームページ`"
      :numberImgPath="`GH-syoukai-top-fukidashi03.png`"
      :numberImgName="`no2`"
      :imgPath="`GH-syoukai-top-monita004.jpg`"
      :imgName="`見栄えの良い、イメージアップにつながるホームページ`"
      :title1="`クライアントの希望や悩み`"
      :title2="`制作のポイント`"
      :des1="`施設の空室状況の問い合わせ対応が多いため、自分たちで空室状況を更新してホームページに表示できる仕組みがほしい。料金について問い合わせが多いので、ホームページ上でシミュレーションできるようにしたい。電話で施設の見学予約を受けているので、ネットで予約を受けられるようにしたい。施設のサービス内容について問い合わせが多いので、ホームページを見ればわかるようにしたい。とにかく人手が足りないので、今やっている業務を減らすことにつながるホームページがほしい。`"
      :des2="`クライアントの希望どおり、①空室状況の更新システム、②料金のシミュレーションシステム、③施設見学の予約システムを実装しました。これによって、お問い合わせへの対応を削減して、業務効率を良くすることができました。また、ホームページ上に情報をしっかりと掲載したことで、お問い合わせ数と入居契約数の増加も実現しました。`"
      :siteUrl="`https://www.lifecare-group.com/`"
      :tableList="tableList4"
      class="space-top-130 wrap2 space-btm-200"
    />
    <div style="background-color: #f2f2f2" class="space-top-70">
      <HomeExampleBox
        :boxTitle="`コストを落としたお値打ちなホームページ`"
        :numberImgPath="`GH-syoukai-top-fukidashi04.png`"
        :numberImgName="`no2`"
        :imgPath="`GH-syoukai-top-monita005.jpg`"
        :imgName="`コストを落としたお値打ちなホームページ`"
        :title1="`クライアントの希望や悩み`"
        :title2="`制作のポイント`"
        :des1="`「政党の予算の問題もありながら、お知らせ投稿機能、ブログ機能、お問い合わせフォームはほしい」という希望を叶えてくれる業者を探していた。岐阜エリアのホームページ制作業者でグレイトヘルプさんのことを耳にしていたので、問い合わせてみたところ、こちらの希望にそった提案をしてくれたため、制作を依頼した。`"
        :des2="`クライアントの希望に寄り添いました。文章と写真を提供していただくことで、コストを落とし、希望価格での制作をしました。予算と実現したい内容をお伝えいただければ、それを実現する方法を柔軟にご提案させていただきます。また、希望の予算で制作して、その後、ホームページを更新してより良くしていくこともできます。`"
        :siteUrl="`https://new-kokumin.gifu.jp/`"
        :tableList="tableList5"
        class="wrap2 space-btm-200"
      />

      <hr class="separation white">

      <HomeExampleBox
      :boxTitle="`売上や集客アップにつながるホームページ`"
      :imgPath="`GH-syoukai-top-monita006.jpg`"
      :imgName="`売上や集客アップにつながるホームページ`"
      :title1="`クライアントの希望や悩み`"
      :title2="`制作のポイント`"
      :des1="`創業してから、ホームページを作らないまま事業をしてきました。そして、それぞれの事業が回っていました。しかし、創業から数年し、取引先に会う際や、融資を受ける際などに「ホームページはないの？」と言われることが増え、ホームページを作る必要性に迫られました。状況として必要となったため、「なるべく安く、でも、しっかりとした会社に見えるホームページがほしい」、「自分たちの時間をかけず、おまかせで丸投げしたい」とグレイトヘルプさんにお願いしました。`"
      :des2="`ご予算の指定をいただき、その予算内で、なるべくクライアント側での業務を減らしてホームページを制作しました。将来の会社の成長を見越し、今後、事業が増えた場合や求人を強化する場合にも、更新費を極力抑えて対応できるように設計しました。良い意味で、丸投げしていただいたので、クライアントに手間をほとんどかけることなく、3週間程度の制作期間でホームページを完成させました。`"
      :siteUrl="`https://robe-international.com/`"
      :tableList="tableList6"
      class="space-top-130 wrap2 space-btm-200p"
    />



    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import UiHeading from "@/components/Ui/Heading.vue";
import HomeExampleBox from "@/components/Home/Example/ExampleBox.vue";
import HomeExampleRankBox from "@/components/Home/Example/RankBox.vue";

export default {
  name: "HomeExample",
  components: {
    UiHeading,
    HomeExampleBox,
    HomeExampleRankBox,
  },
  props: {},
  data() {
    return {
      tableList1: [
        { id: 1, th: "クライアント名", td: "おおたかの森歯科・矯正歯科 様" },
        { id: 2, th: "URL", td: "https://no4618.com" },
        {
          id: 3,
          th: "参考価格",
          td: "制作費：1,375,000円(税込)<br>保守費用：11,000円/月(税込)",
        },
        {
          id: 4,
          th: "特記事項",
          td: "完成後の更新や修正、集客などは別途。文章は弊社の指示のもとクライアントから提供。写真はクライアントから提供。",
        },
      ],
      tableList2: [
        { id: 1, th: "クライアント名", td: "名古屋国際法律事務所 様" },
        { id: 2, th: "URL", td: "https://nagoya-intlaw.com" },
        {
          id: 3,
          th: "参考価格",
          td: "制作費：1,375,000円(税込)<br>保守費用：11,000円/月(税込)",
        },
        {
          id: 4,
          th: "特記事項",
          td: "完成後の更新や修正、集客などは別途。文章は弊社の指示のもとクライアントから提供。写真の撮影を含む。",
        },
      ],
      tableList3: [
        { id: 1, th: "クライアント名", td: "匠味 平野 様" },
        { id: 2, th: "URL", td: "https://takumi-hirano.com" },
        {
          id: 3,
          th: "参考価格",
          td: "制作費：550,000円(税込)<br>保守費用：5,500円/月(税込)",
        },
        {
          id: 4,
          th: "特記事項",
          td: "完成後の更新や修正、集客などは別途。動画・写真撮影1回分を料金に含む。",
        },
      ],
      tableList4: [
        { id: 1, th: "クライアント名", td: "株式会社 ライフケア 様" },
        { id: 2, th: "URL", td: "https://www.lifecare-group.com" },
        {
          id: 3,
          th: "参考価格",
          td: "制作費：1,650,000円(税込)<br>保守費用：16,500円/月(税込)",
        },
        {
          id: 4,
          th: "特記事項",
          td: "完成後の更新や修正、集客などは別途。文章は弊社の指示のもとクライアントから提供。写真はクライアントから提供。予約システムや空室状況管理システムを実装しているため保守料が高くなっています。",
        },
      ],
      tableList5: [
        { id: 1, th: "クライアント名", td: "国民民主党 岐阜県総支部連合会 様" },
        { id: 2, th: "URL", td: "https://new-kokumin.gifu.jp" },
        {
          id: 3,
          th: "参考価格",
          td: "制作費：385,000円(税込)<br>保守費用：3,850円/月(税込)",
        },
        {
          id: 4,
          th: "特記事項",
          td: "完成後の更新や修正、集客などは別途。写真と文章はクライアントから提供。",
        },
      ],
      tableList6: [
        { id: 1, th: "クライアント名", td: "株式会社Robecity International 様" },
        { id: 2, th: "URL", td: "https://robe-international.com" },
        {
          id: 3,
          th: "参考価格",
          td: "制作費：275,000円(税込)<br>保守費用：3,300円/月(税込)",
        },
        {
          id: 4,
          th: "特記事項",
          td: "完成後の更新や修正、集客などは別途。写真はクライアントから提供。",
        },
      ],
    };
  },
  computed: {
    ...mapGetters(["rootUrl"]),
  },
  watch: {},
  methods: {},
};
</script>

<style lang="scss" scoped>
.separation{
  display: none;
  @media screen and (max-width: 767px) {
    display: block;
    margin-bottom: 120px;
  }
}
::v-deep.fs{
  .coment{
    width: 70%!important;
    @media screen and (max-width: 767px) {
      width: 100%!important;
    }
  }
}
</style>