<template>
  <div class="home-reason-reason1">
    <UiBar3
      :type="'C'"
      :barText="'クライアント目線での柔軟なご提案'"
      :imgPath="'GH-syoukai-top-shikaku01.png'"
      style="margin-bottom:30px;"
    />

    <p>
      弊社はクライアントのご希望や目的、ご予算に合わせて、クライアントごとに制作するホームページの内容をオーダーメイドでご提案しています。
    </p>

    <div class="cover">
      <div class="text">
        <ul>
          <li>目的達成するための強力なホームページを制作することも可能</li>
          <li>予算内で実現できるベストなホームページを提案することも可能</li>
          <li>
            コストをなるべく落とすことを優先したホームページを提案することも可能
          </li>
        </ul>
        <p>
          実現したいことと、予算をご提示いただければ、その予算内で提案をしますので、予算超過になることはございません。クライアント目線で柔軟なご提案をいたしますので、ご興味がありましたら、お気軽にご相談くださいませ。
        </p>
      </div>
      <div class="container ratio-1_1">
        <img
          alt="クライアント目線での柔軟なご提案"
          src="@/assets/GH-syoukai-ira-002-1.png"
          class="image"
        />
        <img
          alt="クライアント目線での柔軟なご提案"
          src="@/assets/GH-syoukai-ira-002-2.png"
          class="image"
        />
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import UiBar3 from "@/components/Ui/Bar3.vue";

export default {
  name: "HomeAboutUs",
  components: {
    UiBar3,
  },
  props: {},
  data() {
    return {};
  },
  computed: {
    ...mapGetters(["rootUrl"]),
  },
  watch: {},
  methods: {},
};
</script>

<style lang="scss" scoped>
.home-reason-reason1 {
  .cover {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    margin-top: 30px;
    @media screen and (min-width:768px) and ( max-width:1024px){
      align-items: flex-end;
    }
    @media screen and (max-width: 767px){
      flex-direction: column;
    }
    .text {
      width: 62%;
      @media screen and (max-width: 767px){
        width: 100%;
        margin-bottom: 20px;
      }
      ul{
        list-style-type: disc;
        padding-bottom: 30px;
      }
    }
   .container {
      width: 30%;
      @media screen and (max-width: 767px) {
        // padding-bottom: 300px;
        width: 60%;
      }
    }
  }
}
</style>