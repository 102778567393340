<template>
  <div class="example-rank">
    <div class="wrap cover">
      <div class="title">
        <p>検索結果で上位を獲得中</p>
        <img
          src="@/assets/GH-syoukai-top-kensaku001.png"
          alt="矢印アイコン"
          class="arrow"
        />
      </div>

      <img
        :src="require(`@/assets/${comentImgPath}`)"
        :alt="{ comentImgName }"
        class="coment pc-only"
      />
      <img
        :src="require(`@/assets/${comentImgPathSpOnly}`)"
        :alt="{ comentImgName }"
        class="coment sp-only"
      />
      <img
        :src="require(`@/assets/${resultImgPath}`)"
        alt="検索結果"
        class="result"
      />
      <img
      v-if="coment2ImgPath !== ''"
        :src="require(`@/assets/${coment2ImgPath}`)"
        class="coment2 pc-only"
      />
      <img
      v-if="coment2ImgPathSpOnly !== ''"
        :src="require(`@/assets/${coment2ImgPathSpOnly}`)"
        class="coment2 sp-only"
      />
      <p v-html="description" class="description"></p>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
// import UiHeading from "../../Ui/Heading.vue";
export default {
  name: "UiBox",
  components: {
    // UiHeading,
  },
  props: {
    comentImgPath: String,
    comentImgPathSpOnly: String,
    comentImgName: String,
    coment2ImgPath: { type: String, default: "" },
    coment2ImgPathSpOnly: { type: String, default: "" },
    resultImgPath: String,
    description: String,
  },
  data() {
    return {};
  },
  //   mounted() {
  //   window.addEventListener("scroll", this.getScroll)
  // },
  computed: {
    ...mapGetters(["rootUrl"]),
  },
  methods: {},
};
</script>

<style lang="scss" scoped>
.cover {
  .title {
    background-color: var(--sub);
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 20px 0;
    @media screen and (max-width: 767px) {
      padding: 10px 0;
    }
    p {
      font-size: 26px;
      color: white;
      @media screen and (max-width: 767px) {
        vertical-align: bottom;
        font-size: 20px;
      }
    }
    .arrow {
      width: 50px;
      margin-left: 20px;
      @media screen and (max-width: 767px) {
        width: 30px;
        margin-left: 5px;
        margin-bottom: 5px;
      }
    }
  }
  .coment {
    width: 58%;
    margin-top: 50px;
    @media screen and (max-width: 767px) {
      width: 100%;
      margin-top: 30px;
    }
  }
  .coment2{
    width: 40%;
    margin-bottom: 20px;
    @media screen and(max-width: 767px){
    width: 100%;
    }
  }
  .description{
    @media screen and (max-width: 767px){
      font-size: 16px;
      line-height: 28px;
    }
  }
}
</style>