<template>
  <div class="home-aboutus">
    <UiHeading
      :h="'1'"
      :type="'E3'"
      :text="'(株)グレイトヘルプの<br class=sp-tb-only>ホームページ制作サービス<br class=sp-only>について'"
    />
    <p class="wrap">
      株式会社グレイトヘルプは、お客様のご希望にそったホームページを制作しています。
    </p>
    <UiBar1
      :barNo="'1'"
      :barText="'売上や集客アップにつながる<br class=sp-tb-only>ホームページ'"
      :type="'A'"
    />
    <UiBar1
      :barNo="'2'"
      :barText="'見栄えの良い、イメージ<br class=sp-only>アップにつながるホームページ'"
      :type="'B'"
    />
    <UiBar1
      :barNo="'3'"
      :barText="'業務の手間を削減する機能や<br class=sp-only>システムを搭載したホームページ'"
      :type="'A'"
    />
    <UiBar1
      :barNo="'4'"
      :barText="'コストを落とした<br class=sp-only>お値打ちなホームページ'"
      :type="'B'"
    />
    <p class="wrap des">
      弊社は顧客に寄り添った料金体系と制作内容を提供し、かつ、ウェブのマーケティングや集客において全国的な実績があります。そのため、弊社でホームページを制作したクライアントは、過去10年間で解約は0件です(クライアント都合の廃業による解約2件のみ)。当ページでは、一般公開できない制作事例を公開しています。ご興味を持たれましたら、お気軽にお問い合わせくださいませ。
    </p>
    <div class="wrap bottom">
      <div class="tb-btn-cover">
        <UiTable :tableList="tableList" class="table" />
        <a
          href="https://greathelp.co.jp/"
          target="_blank"
          rel="noopener noreferrer"
        >
          <div class="button">
            <p>株式会社グレイトヘルプの<br class="tb-sp-only">ホームページはこちら</p>
          </div></a
        >
      </div>
      <div class="img-cover">
        <div class="top">
          <img
            alt="GH社内風景1"
            src="@/assets/GH-syoukai-top-001.jpg"
          />
          <img
            alt="GH社内風景2"
            src="@/assets/GH-syoukai-top-002.jpg"
          />
        </div>
        <div class="container ratio-16_9">
          <img
            alt="GHイメージ"
            src="@/assets/GH-syoukai-ira-001-1.png"
            class="image"
          />
          <img
            alt="GHイメージ"
            src="@/assets/GH-syoukai-ira-001-2.png"
            class="image"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import UiHeading from "@/components/Ui/Heading.vue";
import UiBar1 from "@/components/Ui/Bar1.vue";
import UiTable from "@/components/Ui/Table.vue";

export default {
  name: "HomeAboutUs",
  components: {
    UiHeading,
    UiBar1,
    UiTable,
  },
  props: {},
  data() {
    return {
      tableList: [
        { id: 1, th: "企業名", td: "株式会社グレイトヘルプ" },
        { id: 2, th: "代表者名", td: "小関 貴久" },
        {
          id: 3,
          th: "本社所在地",
          td: "岐阜県岐阜市加納桜田町 2-5-2",
        },
        {
          id: 4,
          th: "電話番号",
          td: "058-214-9005",
        },
        {
          id: 5,
          th: "資本金",
          td: "1,000万円",
        },
      ],
    };
  },
  computed: {
    ...mapGetters(["rootUrl"]),
  },
  watch: {},
  methods: {},
};
</script>

<style lang="scss" scoped>
.home-aboutus {
  .des {
    margin: 50px auto !important;
  }
}
.bottom {
  display: flex;
  justify-content: space-between;
  @media screen and (max-width: 767px){
  flex-direction: column;
  }
  .tb-btn-cover {
    width: 48%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    @media screen and (max-width: 767px){
      width: 100%;
    }
    ::v-deep.table {
      th,
      td {
        padding: 10px 0;
        @media screen and (min-width:768px) and ( max-width:1024px){
          padding: 5px 0;
        }
      }
      th {
        color: var(--main);
        width: 30%;
        @media screen and (max-width: 767px){
          width: 100%;
        }
      }
      td{
        @media screen and (max-width: 767px){
          text-align: center;
        }
      }
    }
    a {
      transition: 0.5s;
    }
    a:hover {
      letter-spacing: 0.2rem;
      transition: 0.5s;
      .button {
        background-color: var(--sub);

        p {
          color: white;
        }
      }
    }
    .button {
      // width: calc(100% - 250px);
      border: 1px solid var(--sub);
      margin: 10px 0; 
      padding: 8px 20px;
      @media screen and (max-width: 1024px){
        line-height: 1em;
        padding: 10px 0;
      }

      p {
        color: var(--sub);
        text-align: center;
      }
    }
  }
  .img-cover {
    width: 48%;

    @media screen and (max-width: 767px){
      width: 100%;
      // margin-bottom: 20px;
    }

    .top {
      display: flex;
      justify-content: space-between;
      align-items: flex-start;
      img {
        width: 48%;
      }
      @media screen and (max-width: 767px) {
        margin-top: 30px;
      }
    }
  .container{
    width: 80%;
    margin-top: 10px;
  }
  }
}
.tb-sp-only{
  display: none;
  @media screen and (max-width: 948px){
    display: initial;
  } 
}
</style>